import { useNavigate } from "react-router-dom";

import TopBar from "../Components/TopBar";
import SideBar from "../Components/SideBar";
import { useEffect } from "react";
import { removeToken, setTokenToHeader } from "../utils/utils";
import axios from "axios";
import { BACKEND_URL } from "../utils/serverInfo";
import { SET_USER } from "../actions/types";
import { useContext } from "react";
import { AuthContext, ChatContext, GroupChatContext } from "../contexts";
import ChatBox from "../Components/ChatBox";
import FullPageLoader from "../Components/FullPageLoader";
import $ from "jquery";
import GroupChatBox from "../Components/GroupChatBox";

function MainPage({ children }) {
  const navigate = useNavigate();
  const { authValue, authDispatch } = useContext(AuthContext);
  const { chatValue } = useContext(ChatContext);
  const { groupChatValue } = useContext(GroupChatContext);

  useEffect(() => {
    if (!authValue.isAuthenticated) {
      let token = setTokenToHeader();

      if (token) {
        axios
          .get(`${BACKEND_URL}/dashboard`)
          .then((res) => {
            const { status, data } = res.data;

            window.PushAlertCo.init();

            (window.pushalertbyiw || []).push([
              "onSuccess",
              function (rslt) {
                let push_id = rslt.subscriber_id;
                axios.post(`${BACKEND_URL}/savepushid`, { push_id });
              },
            ]);

            (window.pushalertbyiw || []).push([
              "onReady",
              function (rslt) {
                let { subs_id } = window.PushAlertCo.getSubsInfo();
                if (subs_id) {
                  let push_id = subs_id;
                  axios.post(`${BACKEND_URL}/savepushid`, { push_id });
                }
              },
            ]);

            if (status) {
              authDispatch({
                type: SET_USER,
                payload: data,
              });
            }
          })
          .catch((err) => {
            removeToken();
            setTokenToHeader();
            navigate("/login");
          });
      } else {
        removeToken();
        setTokenToHeader();
        navigate("/login");
      }
    }
  }, [authValue.isAuthenticated]);

  const collapseSidebar = () => {
    if ($(".sidebar-open").length) {
      $(".sidebar-open").addClass("sidebar-closed");
      $(".sidebar-closed").removeClass("sidebar-open");
    }
  };

  return (
    <>
      {authValue.isAuthenticated ? (
        <div className="wrapper">
          <TopBar />
          <SideBar />
          <div className="main-content">
            <div className="content-wrapper">
              <section className="content mt-5 pt-2">
                <div className="container-fluid">{children}</div>
              </section>
            </div>
            {chatValue.show && <ChatBox />}

            {groupChatValue.show && <GroupChatBox />}
          </div>
          <footer className="main-footer">
            <strong>
              Copyright &copy; 2022{" "}
              <a href="https://learntime.com.bd">Learntime BD</a>.
            </strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
              <b>Version</b> 1.0
            </div>
          </footer>

          {window.document.getElementById("sidebar-overlay") ? (
            <div id="sidebar-overlay" onClick={collapseSidebar}></div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <FullPageLoader />
      )}
    </>
  );
}

export default MainPage;
