import certificateIcon from "../images/certificate.png";
import pdfIcon from '../images/pdf.png'

function CertificateWidget() {
  return (
    <div style={{cursor: 'pointer'}} className="box py-3 px-2">
      <div>
        <img style={{ width: 33 }} src={certificateIcon} alt="" />
        <strong className="ml-2">Certificates</strong>
      </div>
      <div className="pt-5 mb-4 text-center">
          <img src={pdfIcon} alt="" />
      </div>
      <p style={{ fontSize: 20 }} className="mb-0 font-weight-bold text-center py-2">Get Your Certificate</p>
    </div>
  );
}

export default CertificateWidget;
