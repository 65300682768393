import { Link } from "react-router-dom";
import notfoundImage from "../images/404.svg";

function NotFound() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-10">
          <img width="100%" src={notfoundImage} alt="" />
        </div>
      </div>
      <div className="row justify-content-center">
        <Link className="eq-main-btn btn px-3 py-2" to={"/"}>
          Back to Home
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
