import MainPage from "../Pages/MainPage";

function withMainPage(Page) {
  return () => (
    <MainPage>
      <Page />
    </MainPage>
  );
}

export default withMainPage;
