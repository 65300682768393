import { useContext } from "react";
import { AuthContext } from "../contexts";

function TagMessage() {
  const {user} = useContext(AuthContext).authValue;
  return (
    <div className="box py-3 px-2">
      {(() => user.user_type === 3)() && (
        <div className="clearfix">
          <div className="float-right">
            <EditBtn />
          </div>
        </div>
      )}

      <p
        style={{ fontSize: 22 }}
        className="font-weight-bold text-danger px-2 mt-2"
      >
        আমি নিয়মিত ভালো বই পড়ব। ফলে পরিস্থিতি বিশ্লেষণ ও সিদ্ধান্ত নেয়ার জন্যে
        প্রয়োজনীয় তথ্য ও জ্ঞান সবসময় আমার হাতের মুঠোয় থাকবে।
      </p>
    </div>
  );
}

function EditBtn() {
  return <button className="eq-main-btn">Edit</button>;
}

export default TagMessage;
