import axios from "axios";
import { useContext} from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LOG_IN} from "../../actions/types";
import { AuthContext } from "../../contexts";

import { BACKEND_URL } from "../../utils/serverInfo";
import { setTokenToHeader } from "../../utils/utils";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const {authValue, authDispatch} = useContext(AuthContext)
  const navigate = useNavigate();

  const loginHandler = (e) => {
    e.preventDefault();
    setDisabled(true);
    axios
      .post(`${BACKEND_URL}/auth/login`, { email, password })
      .then((res) => {
        let { status, data, message } = res.data;
        if (status) {

          toast.success(message)

          setDisabled(false);
          authDispatch({
            type: LOG_IN,
            payload: data,
          });
        }else{
          setDisabled(false);
          toast.error(message)
        }
      })
      .catch(e => {
        setDisabled(false);
      });
  };

  useEffect(() => {
    let token = setTokenToHeader();
    if (token) {
      navigate("/");
    }
  }, [authValue.isAuthenticated, navigate]);

  document.title = "EQ :: Login"

  return (
    <div className="d-flex justify-content-center mt-5">
      <div className="card box px-2">
        <div className="card-header">LOG IN</div>
        <div className="card-body">
          <form>
            <div className="form-group mb-2">
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={disabled}
              />
            </div>
            <div className="form-group mb-2">
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={disabled}
              />
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                disabled={disabled}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                <small>Remember Me</small>
              </label>
            </div>
            <button
              onClick={(e) => loginHandler(e)}
              type="submit"
              className="btn btn-success btn-block mt-1"
              disabled={disabled}
            >
              {disabled ? (<i className="fas fa-spinner fa-spin"></i>) : "Submit"}
            </button>
            <div className="mt-3">
              <span>New Here ?</span> <Link to='/register'>Register</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
