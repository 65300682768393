import stickerLogo from "../images/sticker.png";
import tiger from "../images/tiger.png";
import spiderman from "../images/spiderman.png";
import crocodile from "../images/crocodile.jpg";

function StickerWidget() {
  return (
    <div className="col-md-8 mt-4">
      <div style={{height: 'auto'}} className="box py-3 px-2">
        <div className="mb-4">
          <img style={{width: 30}} src={stickerLogo} alt="" />
          <strong className="ml-2">Your Collected Stickers</strong>
        </div>
        <div className="row mt-4 justify-content-center align-items-center">
          <div className="col-sm-6">
            <img width={"100%"} src={tiger} />
          </div>
          <div className="col-sm-6">
            <img width={"100%"} src={spiderman} />
            <img width={"100%"} src={crocodile} />
          </div>
        </div>
        <div className="mt-3 text-center">
          <button className="eq-main-btn px-3">View All</button>
        </div>
      </div>
    </div>
  );
}

export default StickerWidget;
