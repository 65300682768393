export const authStore = {
    isAuthenticated: false,
    user: {},
}

export const studentsStore = {
    isLoaded: false,
    students: []
};

export const schedulesStore = {
    isLoaded: false,
    schedules: []
}

export const chatStore = {
    show: false,
    conversationId: null,
    partner: null
}

export const convStore = []


export const groupChatStore = {
    show: false,
    conversation: null,
}