import axios from "axios";
import moment from "moment";

export const setTokenToLocal = (token) => {
  localStorage.setItem("EQauthToken", `Bearer ${token}`);
};

export const setTokenToHeader = () => {
  let token = localStorage.getItem("EQauthToken");
  if (token) {
    // Apply to every request
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
  return token;
};

export const removeToken = () => {
  localStorage.removeItem("EQauthToken");
};

const dayArray = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

const getDayArray = (day) => {
  const index = dayArray.indexOf(day);
  const first = dayArray.slice(index, dayArray.length);
  const last = dayArray.slice(0, dayArray.length - first.length);

  return [...first, ...last];
};
const compare = (a, b) => {
  if (a.day === b.day) {
    const [aH, aM] = a.startTime.split(":");
    const [bH, bM] = b.startTime.split(":");

    if (parseInt(aH) === parseInt(bH)) {
      return parseInt(aM) - parseInt(bM);
    } else {
      return parseInt(aH) - parseInt(bH);
    }
  } else {
    return 0;
  }
};

export const sortSchedules = (schedules) => {
  const day = moment().format("ddd");
  const hour = moment().hour();
  const minute = moment().minutes();
  const arr = getDayArray(day);
  schedules.sort((a, b) => arr.indexOf(a.day) - arr.indexOf(b.day));
  let upcoming = schedules.filter((s) => {
    if (s.day === day) {
      const [H, M] = s.startTime.split(":");
      if (parseInt(H) === hour) {
        return M >= minute;
      } else {
        return H >= hour;
      }
    } else {
      return true;
    }
  });

  let finishedToday = schedules.filter((s) => {
    if (s.day === day) {
      const [H, M] = s.startTime.split(":");
      if (parseInt(H) === hour) {
        return M < minute;
      } else {
        return H < hour;
      }
    } else {
      return false;
    }
  });

  upcoming.sort(compare);
  finishedToday.sort(compare);

  upcoming = upcoming.map((s) => {
    s.startTime = changeTo12(s.startTime);
    s.date = moment().add(arr.indexOf(s.day), "days").format("DD-MM-YYYY");
    return s;
  });

  finishedToday = finishedToday.map((s) => {
    s.startTime = changeTo12(s.startTime);
    s.date = moment().add(7, "days").format("DD-MM-YYYY");
    return s;
  });
  schedules = [...upcoming, ...finishedToday];

  return schedules;
};

export function changeTo12(time) {
  let hour = time.split(":")[0];
  let mintSecond = time.substr(hour.length);
  let section = "AM";

  hour = parseInt(hour);

  if (hour > 12 && hour % 12 !== 0) {
    hour = hour % 12;
    if (`${hour}`.length === 1) {
      hour = `0${hour}`;
    }
    section = "PM";
  }

  if (hour === 12) {
    section = "PM";
  }

  return `${hour === 24 || hour === 0 ? 12 : hour}${mintSecond} ${section}`;
}

export const getDiff = (time) => {
  const updatedTime = moment(time);
  const hour = 60 * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  let diff = moment().diff(updatedTime, "seconds");

  if (diff < 60) {
    diff += "s";
  } else if (diff > 60 && diff < hour) {
    diff = moment().diff(updatedTime, "minutes") + "m";
  }  else if (diff > 60 && diff < day) {
    diff = moment().diff(updatedTime, "hours") + "h";
  } else if (diff >= day && diff < week) {
    diff = moment().diff(updatedTime, "days") + "d";
  } else if (diff >= week && diff < month) {
    diff = moment().diff(updatedTime, "weeks") + "w";
  } else if (diff >= month && diff < year) {
    diff = moment().diff(updatedTime, "weeks") + "mo";
  } else {
    diff = moment().diff(updatedTime, "weeks") + "yr";
  }



  return diff;
};

export const sortConversation = (conv) => {
  return conv.sort( ( a , b) => {
    return moment(b.updatedTime).diff(moment(a.updatedTime), 'seconds');
  })
}
