import { LOAD_CONVERSATIONS, CLEAN_CONVERSATIONS } from "../actions/types";

export const convReducer = (state, action) => {
    switch (action.type) {
      case LOAD_CONVERSATIONS:
        return action.payload;
      case CLEAN_CONVERSATIONS:
        return [];
      default:
        return state;
    }
  };