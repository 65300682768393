import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

function StudenModal({ show, handleShow, addStudent }) {
  const [state, setState] = useState({
    isLoading: false,
    name: "",
    email: "",
    age: "",
    gender: "",
    relation: "",
  });

  const onchangeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setState({ ...state, isLoading: true });
    addStudent(state, () => {
      setState({...state, isLoading: false});
    });
  };
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Add Student</Modal.Title>

        <Button variant="danger" onClick={handleShow}>
          <i className="fas fa-times"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Student's Name"
              name="name"
              value={state.name}
              onChange={onchangeHandler}
              disabled={state.isLoading}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Student's Email"
              name="email"
              value={state.email}
              onChange={onchangeHandler}
              disabled={state.isLoading}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Age</Form.Label>
            <Form.Control
              type="number"
              placeholder="Student's Age"
              name="age"
              value={state.age}
              onChange={onchangeHandler}
              disabled={state.isLoading}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              className="form-control"
              name="gender"
              value={state.gender}
              onChange={onchangeHandler}
              disabled={state.isLoading}
            >
              <option value="">Select One</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Student is</Form.Label>
            <Form.Select
              className="form-control"
              name="relation"
              value={state.relation}
              onChange={onchangeHandler}
              disabled={state.isLoading}
            >
              <option value="">Select One</option>
              <option value="Son">My Son</option>
              <option value="Daughter">My Daughter</option>
              <option value="Cousin">My Cousin</option>
              <option value="Me">Me</option>
            </Form.Select>
          </Form.Group>

          <Button
            variant="primary"
            className="w-100"
            type="submit"
            disabled={state.isLoading}
          >
            {state.isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default StudenModal;
