import { LOAD_GROUP_CONVERSATIONS, CLEAN_GROUP_CONVERSATIONS } from "../actions/types";

export const groupConvReducer = (state, action) => {
    switch (action.type) {
      case LOAD_GROUP_CONVERSATIONS:
        return action.payload;
      case CLEAN_GROUP_CONVERSATIONS:
        return [];
      default:
        return state;
    }
  };