import { addStudent, loadStudent } from "../actions/studentAction";
import { ADD_STUDENT, LOAD_STUDENT } from "../actions/types";

export const studentReducer = (state, { type, payload }) => {
  switch (type) {
    case LOAD_STUDENT:
      return loadStudent(state, payload);
    case ADD_STUDENT:
      return addStudent(state, payload);
    default:
      return state;
  }
};
