import classImage from "../images/class_image.png";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../utils/serverInfo";
import moment from "moment";
import { changeTo12, sortSchedules } from "../utils/utils";
import { ScheduleContext } from "../contexts";
import { LOAD_SCHEDULES, UPDATE_SHCEDULE } from "../actions/types";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function NextClass({ userType, limit , items}) {
  const { scheduleValue, scheduleDispatch } = useContext(ScheduleContext);
  const [requestShow, setRequestShow] = useState(false);
  const [pendingShow, setPendingShow] = useState(false);
  const [infoShow, setinfoShow] = useState(false);
  const [scheduleID, setScheduleID] = useState(null);
  const [reschedule, setReschedule] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const navigate = useNavigate();

  const handleShow = (action) => {
    if (action === "Pending") {
      return showPendingModal;
    }

    if (action === "Reshcedule") {
      return showRequestModal;
    }

    if (action === "Info") {
      return showInfoModal;
    }
  };

  const showInfoModal = (schedule = null) => {
    setinfoShow(!infoShow);
    setSchedule(schedule);
  };

  const showRequestModal = (id = null) => {
    setRequestShow(!requestShow);
    setScheduleID(id);
  };

  const showPendingModal = (reschedule = null) => {
    setPendingShow(!pendingShow);
    setReschedule(reschedule);
  };

  const rescheduleRequest = ({ date, time }, callBack) => {
    axios
      .post(`${BACKEND_URL}/reschedule`, { scheduleID, date, time })
      .then((res) => {
        const { status, message, data } = res.data;
        if (status) {
          setRequestShow(false);
          callBack();
          const schedules = sortSchedules(data);
          scheduleDispatch({
            type: LOAD_SCHEDULES,
            payload: schedules,
          });
        } else {
          toast.error(message);
          callBack(false);
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        callBack(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/classSchedules`)
      .then((res) => {
        const schedules = sortSchedules(res.data);
        scheduleDispatch({
          type: LOAD_SCHEDULES,
          payload: schedules,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          toast.error("Something Went Wrong!");
          console.log(err.response);
        }
      });
  }, []);
  return (
    <>
      <div className="box py-3 px-2">
        <div>
          <img src={classImage} alt="" />
          <strong className="ml-2">Next Classes</strong>
        </div>
        <div className="row mt-2">
          {limit ? (
            <>
              {scheduleValue.schedules.slice(0, limit).map((s, i) => (
                <ClassTime
                  schedule={s}
                  key={i}
                  handleShow={handleShow}
                  limit={limit}
                  userType={userType}
                 
                />
              ))}
            </>
          ) : (
            <>
              {scheduleValue.schedules.map((s, i) => (
                <ClassTime
                  schedule={s}
                  key={i}
                  handleShow={handleShow}
                  userType={userType}
                  
                />
              ))}
            </>
          )}
        </div>
      </div>
      <RescheduleModal
        show={requestShow}
        handleShow={showRequestModal}
        rescheduleRequest={rescheduleRequest}
        scheduleDispatch={scheduleDispatch}
      />
      <PendingModal
        show={pendingShow}
        handleShow={showPendingModal}
        reschedule={reschedule}
        userType={userType}
        scheduleDispatch={scheduleDispatch}
      />

      <InfoModal
        show={infoShow}
        handleShow={showInfoModal}
        schedule={schedule}
        scheduleDispatch={scheduleDispatch}
      />
    </>
  );
}

function ClassTime({ userType,schedule, handleShow , limit}) {
  let col = 3;

  if(limit === 3){
    col = 4
  }
  return (
    <div
      className={`px-2 mt-2 text-center col-md-${col}`}
    >
      <div className="bg-light rounded py-2 border">
        <strong>{schedule.course.name}</strong>
        {schedule.isRescheduled === 1 && (
          <sup>
            <span className="badge badge-sm badge-danger">Rescheduled</span>
          </sup>
        )}

        <br />
        <span>
          {schedule.isRescheduled === 1
            ? changeTo12(schedule.rsTime)
            : schedule.startTime}
        </span>
        <br />
        <span>
          {schedule.isRescheduled === 1
            ? moment(schedule.rsDate_).format("DD-MM-YYYY")
            : schedule.date}
        </span>
        <br />
        {
          userType === 5 ? (<small>{schedule.teacher.name}</small>) : (<small>{schedule.student.name}</small>)
        }
        
      </div>
      {schedule.isRescheduled === 1 ? (
        <button
          className="btn btn-danger mt-2"
          onClick={() => handleShow("Info")(schedule)}
        >
          Reshceduled
        </button>
      ) : (
        <>
          {schedule.reschedule ? (
            <button
              className="btn btn-warning mt-2"
              onClick={() => handleShow("Pending")(schedule.reschedule)}
            >
              Pending
            </button>
          ) : (
            <button
              onClick={() => handleShow("Reshcedule")(schedule.id)}
              className="eq-main-btn mt-2"
            >
              Reshcedule
            </button>
          )}
        </>
      )}
    </div>
  );
}

function RescheduleModal({ show, handleShow, rescheduleRequest }) {
  const [state, setSate] = useState({
    isLoading: false,
    date: "",
    time: "",
  });

  const onchangeHandler = (e) => {
    setSate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!state.date) {
      toast.error("Please Select Date!");
    } else if (!state.time) {
      toast.error("Please Enter Time!");
    } else {
      setSate({ ...state, isLoading: true });
      rescheduleRequest(
        { date: state.date, time: state.time },
        (resetState = true) => {
          if (resetState) {
            setSate({
              isLoading: false,
              date: "",
              time: "",
            });
          } else {
            setSate({
              ...state,
              isLoading: false,
            });
          }
        }
      );
    }
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Class Reschedule Request</Modal.Title>

        <Button variant="danger" onClick={() => handleShow()}>
          <i className="fas fa-times"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group className="mb-3">
            <Form.Label>Reschedule Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={state.date}
              onChange={onchangeHandler}
              disabled={state.isLoading}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Reschedule Time</Form.Label>
            <Form.Control
              type="time"
              name="time"
              value={state.time}
              onChange={onchangeHandler}
              disabled={state.isLoading}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="w-100"
            type="submit"
            disabled={state.isLoading}
          >
            {state.isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function InfoModal({ show, handleShow, schedule, scheduleDispatch }) {
  const cancelHandler = () => {
    // e.preventDefault();

    axios
      .post(`${BACKEND_URL}/schedule/${schedule.id}/cancel`)
      .then((res) => {
        let { data, status, message } = res.data;
        if (status) {
          handleShow();
          const schedules = sortSchedules(data);
          scheduleDispatch({
            type: LOAD_SCHEDULES,
            payload: schedules,
          });
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Server Error!");
      });
  };

  return (
    <>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Reschedule Info</Modal.Title>

          <Button variant="danger" onClick={() => handleShow()}>
            <i className="fas fa-times"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped table-bordered">
            <tbody>
              <tr>
                <th>Date</th>
                <td>
                  {schedule && moment(schedule.rsDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <th>Original Date</th>
                <td>{schedule && schedule.date}</td>
              </tr>
              <tr>
                <th>Time</th>
                <td>{schedule && changeTo12(schedule.rsTime)}</td>
              </tr>
              <tr>
                <th>Original Time</th>
                <td>{schedule && schedule.startTime}</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-6">
              <button
                onClick={cancelHandler}
                className="btn btn-block btn-danger btn-block"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function PendingModal({
  show,
  handleShow,
  reschedule,
  userType,
  scheduleDispatch,
}) {
  const [state, setSate] = useState({
    isLoading: false,
  });

  const acceptHandler = () => {
    // e.preventDefault();

    axios
      .post(`${BACKEND_URL}/reschedule/${reschedule.id}/accept`)
      .then((res) => {
        let { data, status, message } = res.data;
        if (status) {
          handleShow();
          const schedules = sortSchedules(data);
          scheduleDispatch({
            type: LOAD_SCHEDULES,
            payload: schedules,
          });
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Server Error!");
      });
  };

  const cancelHandler = () => {
    // e.preventDefault();

    axios
      .post(`${BACKEND_URL}/reschedule/${reschedule.id}/cancel`)
      .then((res) => {
        let { data, status, message } = res.data;
        if (status) {
          handleShow();
          const schedules = sortSchedules(data);
          scheduleDispatch({
            type: LOAD_SCHEDULES,
            payload: schedules,
          });
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Server Error!");
      });
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Reschedule Info</Modal.Title>

        <Button variant="danger" onClick={() => handleShow()}>
          <i className="fas fa-times"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <p>Reschedule Request is Pending...</p>
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <th>Date</th>
              <td>{reschedule && reschedule.date}</td>
            </tr>
            <tr>
              <th>Time</th>
              <td>{reschedule && changeTo12(reschedule.time)}</td>
            </tr>
          </tbody>
        </table>
        <div className="row">
          <div className="col-md-6">
            <button
              onClick={cancelHandler}
              className="btn btn-block btn-danger btn-block"
            >
              Cancel
            </button>
          </div>

          {reschedule && reschedule.requested_by !== userType && (
            <div className="col-md-6">
              <button
                onClick={acceptHandler}
                className="btn btn-block btn-success btn-block"
              >
                Accept
              </button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default NextClass;
