import { useContext } from "react";

function PaymentsWiget({user}) {
  return (
    <div className="col-12 col-sm-4 mt-3">
      <div className="box py-3 px-2">
        <div className="row h-100 align-items-around">
          <div className="col-12">
            <div className="bg-success text-center py-2 rounded">
              <strong style={{ fontSize: 35 }}>{user.profile.totlaClass}</strong>
              <br />
              <span>Total Class</span>
            </div>
          </div>
          <div className="col-12">
            <div className="mt-2">
              <div className="row justify-content-around ">
                <div className="col-6">
                  <div className="eq-badge">
                    <div className="title">$ {user.profile.due}</div>
                    <div className="label">Due</div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="eq-badge">
                    <div className="title">$ {user.profile.availableFund}</div>
                    <div className="label">Available fund</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentsWiget;
