function ContactInfo({ user }) {
  return (
    <div className="col-12 col-sm-8 mt-3">
      <div className="box pt-3">
        <div style={{ fontSize: 18 }} className="row">
          <div className="col-sm-6 mb-3 pl-4">
            <i className="fas fa-phone mr-3"></i>{" "}
            <a className="text-stp font-weight-bold" href={`tel:${user.phone}`}>
              {user.phone}
            </a>
          </div>
          <div className="col-sm-6 mb-3 px-3">
            <i className="fas fa-envelope mr-3"></i>{" "}
            <a
              className="text-stp font-weight-bold"
              href={`mailto:${user.email}`}
            >
              {user.email}
            </a>
          </div>
        </div>

        <div className="px-2">
          <div className="row mt-2">
            <div className="col-md-6 mb-3">
              <div>
                <i className="fas fa-cogs mr-2"></i> <strong>Expertise</strong>
              </div>
              <div className="row">
                { user.profile.expertise && user.profile.expertise.split("//").map((ex, i) => (
                  <div key={i} className="col-sm-4">
                    <div className="eq-badge-sm mt-2">{ex}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <i className="fas fa-clock mr-2"></i>{" "}
              <strong>Available Time</strong>
              <div className="row">
                { user.profile.availableTime && user.profile.availableTime.split("//").map((at, i) => (
                  <div key={i} className="col-sm-4">
                    <div className="eq-badge-sm mt-2">{at}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
