import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import withMainPage from "../../HOC/withMainPage";
import { BACKEND_URL } from "../../utils/serverInfo";

function ChangePassword() {
  const [confirmCode, setConfirmCode] = useState(false)

  return(<>
  {
      confirmCode ? <ConfirmCodeForm /> : <NewPasswordForm setConfirmCode={ setConfirmCode }/>
  }
  
  
  </>);
  
}


function NewPasswordForm ({setConfirmCode}) {
    const [isLoading, setLoading] = useState(false);
    const [submitData, setSubmitData] = useState({
        password: '',
        password_confirmation: '',
        oldPassword: ''
    });

    const newPassRef = useRef();
    const conPassRef = useRef();
    const oldPassRef = useRef();
  
    document.title = "EQ :: Change Password";
  
    const onChangeHandler = (e) => {
        setSubmitData({...submitData, [e.target.id]: e.target.value})
  
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(!submitData.password){
            toast.error("New Password is Required!")
            newPassRef.current.focus();
            return
        }

        if(submitData.password !== submitData.password_confirmation){
            toast.error("Password Don't Match!");
            conPassRef.current.focus();
            return
        }

        if(!submitData.oldPassword){
            toast.error("Please Enter Current Password!");
            oldPassRef.current.focus()
            return
        }

        setLoading(true);
        axios.post(`${BACKEND_URL}/auth/change_password`, submitData).then(res => {
            const {status, message} = res.data;
            if(status){
                toast.success(message)
                setConfirmCode(true)
            }else{
                toast.error(message);
                setLoading(false);
            }
        }).catch(err => {
            toast.error('Something Went Wrong!')
            setLoading(false);
        })
        
    }
  
    return (
      <div className="row mt-4 justify-content-center">
        <div className="col-md-4 col-sm-6">
          <div className="box py-4 px-3">
            <form onSubmit={submitHandler}>
              <h3>Change Password</h3>
              <input
                type="password"
                className="form-control my-3"
                id="password"
                placeholder="New Password"
                value={ submitData.password }
                onChange={onChangeHandler}
                ref={newPassRef}
                disabled={isLoading}
              />
  
              <input
                type="password"
                className="form-control my-3"
                id="password_confirmation"
                placeholder="Confirm New Password"
                value={ submitData.password_confirmation }
                onChange={onChangeHandler}
                ref={conPassRef}
                disabled={isLoading}
              />
  
              <input
                type="password"
                className="form-control my-3"
                id="oldPassword"
                placeholder="Current Password"
                value={ submitData.oldPassword }
                onChange={onChangeHandler}
                ref={oldPassRef}
                disabled={isLoading}
              />
  
              <button type="submit" className="btn btn-primary btn-block" disabled={isLoading}>
                {isLoading ? (<i className="fas fa-spinner fa-spin"></i>) : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
}


function ConfirmCodeForm ({setConfirmCode}) {
    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState();
    const codeRef = useRef();
    const navigate = useNavigate();
  
    document.title = "EQ :: Change Password";
  
    const onChangeHandler = (e) => {
        setCode(e.target.value)
  
    }

    const submitHandler = (e) => {
        e.preventDefault()
        
        if(!code){
            toast.error("Code is Required!")
            codeRef.current.focus();
            return
        }
        setLoading(true);
        axios.post(`${BACKEND_URL}/auth/confirm_pass_code`, {code}).then(res => {
            const {status, message, data} = res.data;
            if(status){
                toast.success(message);
                setLoading(false);
                navigate('/profile')
            }else{
                toast.error(message)
            }
        }).catch(err => {
            toast.error("Somethig Went Wrong!");
            setLoading(false);
        })


    }
  
    return (
      <div className="row mt-4 justify-content-center">
        <div className="col-md-4 col-sm-6">
          <div className="box py-4 px-3">
            <form onSubmit={submitHandler}>
              <h3>Confirm Code</h3>
              <input
                type="code"
                className="form-control my-3"
                id="code"
                placeholder="Confirmation Code"
                value={code }
                onChange={onChangeHandler}
                ref={codeRef}
                disabled={isLoading}
              />
              <button type="submit" className="btn btn-primary btn-block" disabled={isLoading}>
                {isLoading ? (<i className="fas fa-spinner fa-spin"></i>) : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
}

export default withMainPage(ChangePassword);
