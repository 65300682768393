import homeIcon from "../images/homework.png";

function HomeWorkWidget() {
  return (
    <div className="col-12 col-sm-4 mt-3">
      <div className="box py-3 px-2">
        <div>
          <img src={homeIcon} alt="" />
          <strong className="ml-2">Home Works</strong>
        </div>
        <p className="pt-5 pb-3 text-center">You have 2 pending Homeworks</p>
        <button className="eq-main-btn btn-block py-2 mb-2">View</button>
      </div>
    </div>
  );
}

export default HomeWorkWidget;
