import { useContext } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { AuthContext } from "../contexts";

function SideBar() {
  const { user } = useContext(AuthContext).authValue;

  return (
    <aside className="main-sidebar sidebar-light-primary elevation-1">
      <a href="#" className="brand-link text-center">
        <span className="brand-text font-weight-bold">{user.name}</span>
      </a>

      <div className="sidebar">
        <small>
          <strong className="text-muted">MENU</strong>
        </small>
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <EQLink to="/" className="nav-link">
                <i className="nav-icon fas fa-fire"></i>
                <p className="ml-1">Dashboard</p>
              </EQLink>
            </li>
            {(() => user.type === 5)() || (
              <li className="nav-item">
                <EQLink to="/students" className="nav-link">
                  <i className="nav-icon fas fa-users"></i>
                  <p className="ml-1">Students</p>
                </EQLink>
              </li>
            )}

            <li className="nav-item">
              <EQLink to="/classes" className="nav-link">
                <i className="nav-icon fas fa-chalkboard-teacher"></i>
                <p className="ml-1">Classes</p>
              </EQLink>
            </li>

            <li className="nav-item">
              <EQLink to="/chats" className="nav-link">
                <i className="nav-icon fas fa-comments"></i>
                <p className="ml-1">Chats</p>
              </EQLink>
            </li>

            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="nav-icon fas fa-file-alt"></i>
                <p className="ml-1">Tests</p>
              </a>
            </li>

            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="nav-icon fas fa-file"></i>
                <p className="ml-1">Reports</p>
              </a>
            </li>

            {(() => user.type === 3)() && (
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-sticky-note"></i>
                  <p className="ml-1">Revision Notes</p>
                </a>
              </li>
            )}

            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="nav-icon fas fa-home"></i>
                <p className="ml-1">Home Work</p>
              </a>
            </li>

            <li className="nav-item">
              <a href="#" className="nav-link">
                <i className="nav-icon fas fa-video"></i>
                <p className="ml-1">Video Library</p>
              </a>
            </li>

            {(() => user.type === 5)() || (
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-dollar-sign"></i>
                  <p className="ml-1">Payments</p>
                </a>
              </li>
            )}

            {(() => user.type === 3)() || (
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-certificate"></i>
                  <p className="ml-1">Certificate</p>
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
}

function EQLink({ children, to, className, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link to={to} className={`${className} ${match && "active"}`} {...props}>
      {children}
    </Link>
  );
}

export default SideBar;
