import { LOAD_SCHEDULES, UPDATE_SHCEDULE } from "../actions/types";
import { loadSchedule, updateSchedule } from "../actions/scheduleAction";

export const scheduleReducer = (state, { type, payload }) => {
  switch (type) {
    case LOAD_SCHEDULES:
      return loadSchedule(state, payload);
    case UPDATE_SHCEDULE:
      return updateSchedule(state, payload);
    default:
      return state;
  }
};
