import { useContext } from "react";
import NextClass from "../Components/NextClass";
import { AuthContext } from "../contexts";
import withMainPage from "../HOC/withMainPage";

function Classes() {
  const { authValue} = useContext(AuthContext);
  document.title = "EQ :: Classes"

  return (
    <div className="row justify-content-center mt-5">
      <div className="col-11">
        <NextClass userType={authValue.user.type} limit={false} />
      </div>
    </div>
  );
}

export default withMainPage(Classes);
