import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SET_USER } from "../actions/types";
import { AuthContext } from "../contexts";
import withMainPage from "../HOC/withMainPage";

import userIcon from "../images/user.png";
import { BACKEND_URL } from "../utils/serverInfo";

function ProfilePage() {
  const { user } = useContext(AuthContext).authValue;
  const [isEditing, setEditing] = useState(false);

  document.title = "EQ :: Profile";

  return (
    <div className="row mt-4 justify-content-center">
      <div className="col-md-8">
        <div className="box py-4 px-3">
          <div className="clearfix">
            <h4 className="float-left">
              {isEditing ? "Edit Profile" : user.name}
            </h4>
            <div className="float-right">
              {isEditing ? (
                <button
                  onClick={() => setEditing(false)}
                  className="btn btn-danger"
                >
                  <i className="fas fa-times"></i>
                </button>
              ) : (
                <button
                  onClick={() => setEditing(true)}
                  className="eq-main-btn"
                >
                  <i className="fas fa-user-edit"></i>
                </button>
              )}
            </div>
          </div>
          {isEditing ? (
            <ProfileEditForm user={user} setEditing={setEditing} />
          ) : (
            <ProfileInfo user={user} />
          )}
        </div>
      </div>
    </div>
  );
}

function ProfileEditForm({ setEditing, user }) {
  const { authValue, authDispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [state, setSate] = useState({
    name: user.name,
    phone: user.phone,
    country: user.country,
    bio: user.profile.bio,
    expertise: user.profile.expertise,
    availableTime: user.profile.availableTime,
    isLoading: false,
  });

  const inputHandler = (e) => {
    setSate({ ...state, [e.target.id]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setSate({ ...state, isLoading: true });
    let usrinfo = {
      name: state.name,
      phone: state.phone,
      country: state.country,
    };

    if (authValue.user.type === 3) {
      // Teacher
      usrinfo = {
        ...usrinfo,
        bio: state.bio,
        expertise: state.expertise,
        availableTime: state.availableTime,
      };
    }

    axios
      .post(`${BACKEND_URL}/profile/${authValue.user.id}`, usrinfo)
      .then((res) => {
        const { status, message, data} = res.data;

        if (status) {
          toast.success(message);
          authDispatch({ type: SET_USER, payload: data });
          setEditing(false);
        } else {
          toast.error(message);
        }
        setSate({ ...state, isLoading: false });
      })
      .catch((err) => {
        toast.error("Something Went Wrong!!");
        setSate({ ...state, isLoading: false });
        
      });
  };

  return (
    <>
      <hr />
      <div style={{ maxWidth: 400 }} className="mx-auto">
        <form onSubmit={submitHandler}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={state.name}
              onChange={inputHandler}
              disabled={state.isLoading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              value={state.phone}
              onChange={inputHandler}
              disabled={state.isLoading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              className="form-control"
              id="country"
              value={state.country}
              onChange={inputHandler}
              disabled={state.isLoading}
            />
          </div>

          {user.type === 3 && (
            <>
              <div className="form-group">
                <label htmlFor="country">Bio</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="bio"
                  value={state.bio}
                  onChange={inputHandler}
                  disabled={state.isLoading}
                >
                  {state.bio}
                </textarea>
              </div>

              <div className="form-group">
                <label htmlFor="bio">Expertise</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="expertise"
                  value={state.expertise}
                  onChange={inputHandler}
                  disabled={state.isLoading}
                >
                  {state.expertise}
                </textarea>
              </div>

              <div className="form-group">
                <label htmlFor="availableTime">Avialable Time</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="availableTime"
                  value={state.availableTime}
                  onChange={inputHandler}
                  disabled={state.isLoading}
                >
                  {state.availableTime}
                </textarea>
              </div>
            </>
          )}

          <button
            type="submit"
            disabled={state.isLoading}
            className="btn btn-primary px-5"
          >
            {state.isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              "Update"
            )}
          </button>
        </form>
      </div>
    </>
  );
}

function ProfileInfo({ user }) {
  return (
    <>
      <hr />
      <div className="mt-3 text-center">
        <img width={150} src={userIcon} alt="Profile" />
        <hr />
      </div>
      <div style={{ maxWidth: 400 }} className="mx-auto">
        <table className="table table-striped text-center table-bordered">
          <tbody>
            <tr>
              <th scope="row">Name</th>
              <td>{user.name}</td>
            </tr>
            <tr>
              <th scope="row">Country</th>
              <td>{user.country ? user.country : ""}</td>
            </tr>
            <tr>
              <th scope="row">Phone</th>
              <td>{user.phone ? user.phone : ""}</td>
            </tr>
            <tr>
              <th scope="row">Email</th>
              <td>{user.email ? user.email : ""}</td>
            </tr>
            <tr>
              <th scope="row">With Us From</th>
              <td>{moment(user.created_at).format("MMM YYYY")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default withMainPage(ProfilePage);
