import { useContext } from "react";
import { AuthContext } from "../contexts";
import MessageBox from "../Components/MessageBox";
import NextClass from "../Components/NextClass";
import ReportTable from "../Components/ReportTable";
import TagMessage from "../Components/TagMessage";

import ProfileInfo from "../Components/ProfileInfo";
import ContactInfo from "../Components/ContactInfo";
import HomeWorkWidget from "../Components/HomeWorkWidget";
import TestsWidget from "../Components/TestsWidget";
import CertificateWidget from "../Components/CertificateWidget";
import StickerWidget from "../Components/StickerWidget";
import PaymentsWiget from "../Components/PymentWiget";
import StudetnsWidget from "../Components/StudentsWidget";
import withMainPage from "../HOC/withMainPage";



const reports = [
  {
    time: "9:00 PM",
    course: "Noorani Qaida",
    date: "22-02-2022",
    student: "Imran Ali",
    studentId: "EQS_1001",
    studentCountry: "Italy",
    teacher: "Sobuj Ahmed",
    teacherId: "EQT_1001",
    pending: false,
  },
  {
    time: "9:00 PM",
    course: "Noorani Qaida",
    date: "22-02-2022",
    student: "Imran Ali",
    studentId: "EQS_1001",
    studentCountry: "Italy",
    teacher: "Sobuj Ahmed",
    teacherId: "EQT_1001",
    pending: true,
  },
  {
    time: "9:00 PM",
    course: "Noorani Qaida",
    date: "22-02-2022",
    student: "Imran Ali",
    studentId: "EQS_1001",
    studentCountry: "Italy",
    teacher: "Sobuj Ahmed",
    teacherId: "EQT_1001",
    pending: false,
  },
  {
    time: "9:00 PM",
    course: "Noorani Qaida",
    date: "22-02-2022",
    student: "Imran Ali",
    studentId: "EQS_1001",
    studentCountry: "Italy",
    teacher: "Sobuj Ahmed",
    teacherId: "EQT_1001",
    pending: true,
  },
  {
    time: "9:00 PM",
    course: "Noorani Qaida",
    date: "22-02-2022",
    student: "Imran Ali",
    studentId: "EQS_1001",
    studentCountry: "Italy",
    teacher: "Sobuj Ahmed",
    teacherId: "EQT_1001",
    pending: false,
  },
];

function Dashboard() {
  const {user} = useContext(AuthContext).authValue;
  document.title = "EQ :: Dashboard"
  return (
    <>
      <div className="row">
        <ProfileInfo />

        {(() => user.type === 3)() && <ContactInfo user={user} />}
        {(() => user.type === 5)() && (
          <>
            <HomeWorkWidget />
            <TestsWidget />
          </>
        )}
        {(() => user.type === 4)() && (
          <>
            <PaymentsWiget user={user} />
            
            <StudetnsWidget />
          </>
        )}
      </div>

      {(() => user.type !== 5)() && (
        <div className="mt-4">
          <NextClass userType={user.type} limit={4}/>
        </div>
      )}

      {(() => user.type === 5)() && (
        <div className="mt-4">
          <div className="row">
            <div className="col-12 col-md-8">
              <NextClass userType={5} limit={3} />
            </div>
            <div className="col-12 col-md-4">
              <CertificateWidget />
            </div>
          </div>
        </div>
      )}

      <div className="my-4">
        <TagMessage />
      </div>

      <div className="mb-4">
        <div className="row">
          {(() => user.type !== 5)() && (
            <ReportTable reports={reports} />
          )}
          <MessageBox />
          {(() => user.type === 5)() && <StickerWidget />}
        </div>
      </div>
    </>
  );
}

export default withMainPage(Dashboard);
