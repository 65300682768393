import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ADD_STUDENT, LOAD_STUDENT } from "../actions/types";
import { AuthContext, StudentContext } from "../contexts";
import studentLogo from "../images/students.png";
import { BACKEND_URL } from "../utils/serverInfo";
import StudenModal from "./StudentModal";

function StudetnsWidget() {
  const [show, setState] = useState(false);
  const { studentValue, studentDispatch } = useContext(StudentContext);
  const {authValue} = useContext(AuthContext)

  const handleShow = () => {
    setState(!show);
  };

  useEffect(() => {
    if (!studentValue.isLoaded) {
      axios
        .get(`${BACKEND_URL}/students`)
        .then((res) => {
          const { status, message, data } = res.data;
          if (status) {
            studentDispatch({ type: LOAD_STUDENT, payload: data });
          } else {
            toast.error(message);
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!!");
        });
    }
  }, [authValue.isAuthenticated]);

  const addStudent = (studentData, callback) => {
    axios
      .post(`${BACKEND_URL}/students`, studentData)
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          handleShow();
          studentDispatch({ type: ADD_STUDENT, payload: data });
          toast.success(message);
        } else {
          toast.error(message);
        }
        callback();
      })
      .catch((err) => {
        callback();
        toast.error("Something Went Wrong!");
      });
  };
  const students = studentValue.students.slice(0, 5);
  return (
    <>
      <div className="col-12 col-sm-4 mt-3">
        <div className="box py-3 px-2">
          <div className="mb-3">
            <img src={studentLogo} alt="" />
            <strong className="ml-2">Students</strong>
          </div>
          <div
            style={{ height: 110, overflowY: "auto" }}
            className="d-flex align-items-center"
          >
            {studentValue.isLoaded || (
              <div className="w-100 text-center">
                <i className="fas fa-spinner fa-spin fa-2x"></i>
              </div>
            )}

            {studentValue.isLoaded &&
              (() => (
                <>
                  {students.length ? (
                    <div className="w-100">
                      {students.map((st, index) => (
                        <div key={index} className="mt-2 rounded bg-light px-2">
                          <strong>{st.name}</strong>
                          <br />
                          <small>ID: {st.id}</small>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="w-100 text-center">
                      No Student Available
                    </div>
                  )}
                </>
              ))()}
          </div>

          <div className="clearfix px-3 mt-3">
            <Link to="/students" className="eq-main-btn btn-sm float-left px-3">
              View All
            </Link>
            <button
              onClick={handleShow}
              className="btn float-right btn-sm btn-success px-3"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <StudenModal
        show={show}
        handleShow={handleShow}
        addStudent={addStudent}
      />
    </>
  );
}

export default StudetnsWidget;
