import { HIDE_CHAT_BOX, SHOW_CHAT_BOX } from "../actions/types";

export const chatReducer = (state, action) => {
  const {conversationId, partner} = action.payload;
  switch (action.type) {
    case SHOW_CHAT_BOX:
      return { show: true , conversationId, partner};
    case HIDE_CHAT_BOX:
      return { show: false, conversationId: null, partner : null };
    default:
      return state;
  }
};
