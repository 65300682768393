import { login, logout, setUser } from "../actions/authAction";
import { LOG_IN, LOG_OUT, SET_USER } from "../actions/types";

export const authReducer = (state, action) => {
  switch (action.type) {
    case LOG_IN:
      return login(state, action.payload);
    case LOG_OUT:
      return logout(state);
      case SET_USER:
        // console.log({...state, isAuthenticated: true, user: action.payload})
        return setUser(state, action.payload);
    default:
      return state;
  }
};
