import peddingImage from "../images/pending.png";
import successImage from "../images/success.svg";
import classReport from "../images/classReports.png"

function ReportTable({ reports }) {
  return (
    <div className="col-md-8 mt-4">
      <div style={{height: 'auto'}} className="box py-3 px-2">
        <div className="mb-4">
          <img src={classReport} alt="" />
          <strong className="ml-2">Class Reports</strong>
        </div>

        <div className="table-responsive-lg">
          <table className="table table-striped table-bordered text-center">
            <thead style={{ backgroundColor: "#3956A1", color: "#fff" }}>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">St ID</th>
                <th scope="col">Country</th>
                <th scope="col">Course</th>
                <th scope="col">Date & Time</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((re, i) => (
                <Tr report={re} key={i} />
              ))}
            </tbody>
          </table>

          <div className="text-center mt-2">
            <button className="eq-main-btn px-3">view All</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Tr({ report }) {
  return (
    <tr>
      <th>{report.student}</th>
      <td>{report.studentId}</td>
      <td>{report.studentCountry}</td>
      <td>{report.course}</td>
      <td>
        {report.date} <br />
        {report.time}
      </td>
      <td>
        <img
          width={20}
          src={report.pending ? peddingImage : successImage}
          alt=""
        />
      </td>
      <td>
        <button className="eq-main-btn">Edit</button>
      </td>
    </tr>
  );
}

export default ReportTable;
