import axios from "axios";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LOG_OUT } from "../actions/types";
import { AuthContext } from "../contexts";
import { BACKEND_URL } from "../utils/serverInfo";

function TopBar() {
  const { authValue, authDispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    axios.post(`${BACKEND_URL}/auth/logout`).then(res => {
      authDispatch({type: LOG_OUT})
      navigate('/login')
    }).catch(err => {
      authDispatch({type: LOG_OUT})
      navigate('/login')
    })
  }
  return (
    <nav className="main-header navbar navbar-expand navbar-main navbar-dark">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars"></i>
          </a>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="fullscreen"
            href="#"
            role="button"
          >
            <i className="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
        <li className="nav-item dropdown user-menu">
          <a
            href="#!"
            className=" btn normal-btn dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              style={{ width: 25 }}
              src="assets/img/user2-160x160.jpg"
              className="img-circle"
            />

            <span className="ml-3 mr-2">{authValue.user.name}</span>
          </a>
          <ul className="dropdown-menu rounded dropdown-menu-sm dropdown-menu-right border-0">
            <span className="dropdown-item text-muted">Accounts</span>

            <Link to="/profile" className="dropdown-item">
              <i className="fas fa-user mr-2"></i>
              <span className="ml-2">Profile</span>
            </Link>

            <Link to="/change_password" className="dropdown-item">
              <i className="fas fa-key mr-2"></i>
              <span className="ml-2">Change Password</span>
            </Link>

            <Link to="/change_email" className="dropdown-item">
              <i className="fas fa-envelope mr-2"></i>
              <span className="ml-2">Change Email</span>
            </Link>

            <div className="dropdown-divider mb-0"></div>

            <button
              onClick={logout}
              className="dropdown-item text-danger py-2"
            >
              <i className="fas fa-power-off mr-2"></i>
              <span className="ml-2">Logout</span>
            </button>
          </ul>
        </li>
      </ul>
    </nav>
  );
}

export default TopBar;
