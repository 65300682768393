import { setTokenToLocal, setTokenToHeader, removeToken } from "../utils/utils";

export const login = (state, data) => {
  setTokenToLocal(data.token.plainTextToken);
  setTokenToHeader();
  return {
    ...state,
    isAuthenticated: true,
    user: data.user
  };
};

export const logout = (state) => {
  removeToken();
  setTokenToHeader();
  return { ...state, isAuthenticated: false, user: {} };
};

export const setUser = (state, data) => {
  return { ...state, isAuthenticated: true, user: { ...state.user, ...data } };
};
