import { useMemo } from "react";
import { Pagination, Table } from "react-bootstrap";
import { useTable } from "react-table";

function EQTable({ columns, data }) {
  columns = useMemo(() => columns, []);
  data = useMemo(() => data, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const header = headerGroups[0];
  return (
    <>
      <Table striped hover bordered responsive {...getTableProps()}>
        <thead>
          <tr {...header.getHeaderGroupProps()}>
            {header.headers.map((head) => (
              <th {...head.getHeaderProps()}>{head.render("Header")}</th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default EQTable;
