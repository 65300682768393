import { Link } from "react-router-dom";
import chatsImage from "../images/chats.png";
import Conversations from "./Conversations";

function MessageBox() {
  return (
    <div className="col-sm-8 col-md-4 mt-4">
      <div className="box py-3 px-2">
        <div className="mb-2">
          <img src={chatsImage} alt="" />
          <strong className="ml-2">Chats</strong>
        </div>
        <Conversations />
        <div className="mt-3 text-center">
          <Link to="/chats">
            <button className="eq-main-btn px-3"> View All</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MessageBox;
