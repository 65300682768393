import { useContext } from "react";
import { AuthContext } from "../contexts";
import coinImage from "../images/coin.png";

function ProfileInfo() {
  const { user } = useContext(AuthContext).authValue;
  return (
    <div className="col-12 col-sm-4 mt-3">
      <div className="box py-3">
        <div className="row h-100 align-items-around">
          <div className="col-12">
            <div className="row justify-content-center align-items-center pt-3">
              <div className="col-4 text-center">
                <img
                  style={{ width: 50 }}
                  src="assets/img/user2-160x160.jpg"
                  className="img-circle border"
                />
              </div>
              <div className="col-8">
                <h5 className="mb-0 font-weight-bold">{user.name}</h5>
                <small className="text-muted">
                  <i className="fas fa-map-marker mr-2"></i> {user.country}
                </small>
              </div>
            </div>
          </div>
          <div className="col-12">
            {(() => user.type === 3)() ? <BalanceInfo user={user} /> : ""}
            {(() => user.type === 5)() ? <CoinInfo user={user} /> : ""}
            {(() => user.type === 4)() ? <ContactInfo user={user} /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

function BalanceInfo({user}) {
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-around ">
        <div className="eq-badge">
          <div className="title">{user.profile.total_class ? user.profile.total_class : 0}</div>
          <div className="label">Class Completed</div>
        </div>

        <div className="eq-badge">
          <div className="title">
          {user.profile.ballance ? user.profile.ballance : 0} <sub style={{ fontSize: 9 }}>tk</sub>
          </div>
          <div className="label">Ballance</div>
        </div>
      </div>
    </div>
  );
}

function CoinInfo({user}) {
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-around ">
        <div className="eq-badge w-75 d-flex justify-content-around align-items-center py-3">
          <img src={coinImage} alt="" />
          <div style={{ fontSize: 35 }}>{user.profile.coin ? user.profile.coin : 0}</div>
        </div>
      </div>
    </div>
  );
}

function ContactInfo({ user }) {
  return (
    <div className="mt-4 d-flex justify-content-center">
      <div style={{ fontSize: 18 }}>
        <div className="pl-3">
          {(() => user.phone)() && (
            <>
              <i className="fas fa-phone mr-3"></i>
              <a
                className="text-stp font-weight-bold"
                href={`tel:${user.phone}`}
              >
                {user.phone}
              </a>
            </>
          )}
        </div>
        <div className="pl-3">
          <i className="fas fa-envelope mr-3"></i>
          <a
            className="text-stp font-weight-bold"
            href={`mailto:${user.email}`}
          >
            {user.email}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProfileInfo;
