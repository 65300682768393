import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  AuthContext,
  ScheduleContext,
  StudentContext,
  ChatContext,
  ConversationsContext,
  GroupConversationsContext,
  GroupChatContext,
} from "./contexts";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Auth/Login";
import ProfilePage from "./Pages/ProfilePage";
import { authReducer } from "./reducers/authReducer";
import {
  authStore,
  chatStore,
  convStore,
  groupChatStore,
  GroupChatStore,
  schedulesStore,
  studentsStore,
} from "./stores";

import "react-toastify/dist/ReactToastify.css";
import { studentReducer } from "./reducers/studentReducer";
import Students from "./Pages/Students";
import { setTokenToHeader } from "./utils/utils";
import { scheduleReducer } from "./reducers/scheduleReducer";
import Chats from "./Pages/Chats";
import { chatReducer } from "./reducers/chatReducer";
import { convReducer } from "./reducers/conversationsReducer";
import Classes from "./Pages/Classes";
import ChangePassword from "./Pages/Auth/ChangePassword";
import NotFound from "./Pages/NotFound";
import Register from "./Pages/Auth/Register";
import axios from "axios";
import ChangeEmail from "./Pages/Auth/ChangeEmail";
import { groupChatReducer } from "./reducers/groupChatReducer";
import { groupConvReducer } from "./reducers/groupConversationReducer";

// axios.defaults.headers.common['Accept'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

setTokenToHeader();
const App = () => {
  const [authValue, authDispatch] = useReducer(authReducer, authStore);
  const [studentValue, studentDispatch] = useReducer(
    studentReducer,
    studentsStore
  );
  const [scheduleValue, scheduleDispatch] = useReducer(
    scheduleReducer,
    schedulesStore
  );
  const [chatValue, chatDispatch] = useReducer(chatReducer, chatStore);
  const [convValue, convDispatch] = useReducer(convReducer, convStore);

  const [groupChatValue, groupChatDispatch] = useReducer(
    groupChatReducer,
    groupChatStore
  );
  const [groupConvValue, groupConvDispatch] = useReducer(
    groupConvReducer,
    convValue
  );

  return (
    <>
      <ToastContainer autoClose={2000} position={"top-right"} />
      <AuthContext.Provider value={{ authValue, authDispatch }}>
        <ConversationsContext.Provider value={{ convValue, convDispatch }}>
          <GroupConversationsContext.Provider
            value={{ groupConvValue, groupConvDispatch }}
          >
            <GroupChatContext.Provider
              value={{ groupChatValue, groupChatDispatch }}
            >
              <ChatContext.Provider value={{ chatValue, chatDispatch }}>
                <StudentContext.Provider
                  value={{ studentValue, studentDispatch }}
                >
                  <ScheduleContext.Provider
                    value={{ scheduleValue, scheduleDispatch }}
                  >
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/students" element={<Students />} />
                      <Route path="/classes" element={<Classes />} />
                      <Route path="/chats" element={<Chats />} />
                      <Route path="/profile" element={<ProfilePage />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route
                        path="/change_password"
                        element={<ChangePassword />}
                      />
                      <Route path="/change_email" element={<ChangeEmail />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </ScheduleContext.Provider>
                </StudentContext.Provider>
              </ChatContext.Provider>
            </GroupChatContext.Provider>
          </GroupConversationsContext.Provider>
        </ConversationsContext.Provider>
      </AuthContext.Provider>
    </>
  );
};

export default App;
