import withMainPage from "../HOC/withMainPage";
import { useContext, useEffect, useState } from "react";
import { AuthContext, ChatContext, GroupChatContext, StudentContext } from "../contexts";
import { toast } from "react-toastify";
import { HIDE_GROUP_CHAT_BOX, LOAD_STUDENT, SHOW_CHAT_BOX } from "../actions/types";
import axios from "axios";
import { BACKEND_URL } from "../utils/serverInfo";
import EQTable from "../Components/EQTable";

function Students() {
  const { authValue } = useContext(AuthContext);
  const { studentValue, studentDispatch } = useContext(StudentContext);
  const { chatDispatch } = useContext(ChatContext);
  const { groupChatDispatch } = useContext(GroupChatContext);
  const [columns, setColulmns] = useState([
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: `${authValue.user.type === 3 ? "Parent" : "Teachers"}`,
      Cell: ({ value }) => {
        if (value) {
          return (
            <>
              {Array.isArray(value) ? (
                <ul className="list-group">
                  {value.map((vl) => (
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <span>{vl.name}</span>
                          <br /> <span>{vl.id}</span>
                        </div>
                        <div>
                          <button
                            onClick={() => viewChatbox(vl.user_id)}
                            className="btn btn-sm btn-primary"
                          >
                            <i className="fas fa-comment"></i>
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span>{value.name}</span>
                    <br /> <span>{value.id}</span>
                  </div>
                  <div>
                    <button
                      onClick={() => viewChatbox(value.user_id)}
                      className="btn btn-sm btn-primary"
                    >
                      <i className="fas fa-comment"></i>
                    </button>
                  </div>
                </div>
              )}
            </>
          );
        } else {
          return <></>;
        }
      },
      accessor: `${authValue.user.type === 3 ? "parent" : "teachers"}`,
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      Cell: ({ value }) => {
        return (
          <>
            <button
              onClick={() => viewChatbox(value)}
              className="btn btn-sm btn-primary"
            >
              <i className="fas fa-comment"></i>
            </button>
          </>
        );
      },
      accessor: "user_id",
    },
  ]);

  useEffect(() => {
    setColulmns([
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: `${authValue.user.type === 3 ? "Parent" : "Teachers"}`,
        Cell: ({ value }) => {
          if (value) {
            return (
              <>
                {Array.isArray(value) ? (
                  <ul className="list-group">
                    {value.map((vl) => (
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span>{vl.name}</span>
                            <br /> <span>{vl.id}</span>
                          </div>
                          <div>
                            <button
                              onClick={() => viewChatbox(vl.user_id)}
                              className="btn btn-sm btn-primary"
                            >
                              <i className="fas fa-comment"></i>
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <span>{value.name}</span>
                      <br /> <span>{value.id}</span>
                    </div>
                    <div>
                      <button
                        onClick={() => viewChatbox(value.user_id)}
                        className="btn btn-sm btn-primary"
                      >
                        <i className="fas fa-comment"></i>
                      </button>
                    </div>
                  </div>
                )}
              </>
            );
          } else {
            return <></>;
          }
        },
        accessor: `${authValue.user.type === 3 ? "parent" : "teachers"}`,
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        Cell: ({ value }) => {
          return (
            <>
              <button
                onClick={() => viewChatbox(value)}
                className="btn btn-sm btn-primary"
              >
                Send Message
              </button>
            </>
          );
        },
        accessor: "user_id",
      },
    ]);

    if (!studentValue.isLoaded) {
      axios
        .get(`${BACKEND_URL}/students`)
        .then((res) => {
          const { status, message, data } = res.data;
          if (status) {
            studentDispatch({ type: LOAD_STUDENT, payload: data });
          } else {
            toast.error(message);
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!!");
        });
    }
  }, [studentValue.isLoaded, studentDispatch, authValue.user.type]);

  const viewChatbox = (id) => {

    chatDispatch({
      type: SHOW_CHAT_BOX,
      payload: {
        conversationId: null,
        partner: id,
      },
    });

    groupChatDispatch({
      type: HIDE_GROUP_CHAT_BOX
    });
  };

  document.title = "EQ :: Students";

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-md-11 bg-white box px-3">
          <h4 className="my-4">Students</h4>
          <EQTable columns={columns} data={studentValue.students} />
        </div>
      </div>
    </>
  );
}

export default withMainPage(Students);
