import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  HIDE_CHAT_BOX,
  LOAD_GROUP_CONVERSATIONS,
  SHOW_GROUP_CHAT_BOX,
} from "../actions/types";
import {
  AuthContext,
  ChatContext,
  GroupChatContext,
  GroupConversationsContext,
} from "../contexts";
import userImage from "../images/user.png";
import { BACKEND_URL } from "../utils/serverInfo";
import { getDiff, sortConversation } from "../utils/utils";

function GroupConversations() {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const [showAllParticipantModal, setShowAllParticipantModal] = useState(false);
  const [currentConv, setCurrentConv] = useState(null);
  const [availableMembers, setAvailableMembers] = useState([]);
  const { authValue } = useContext(AuthContext);
  const { groupChatDispatch } = useContext(GroupChatContext);
  const { groupConvValue, groupConvDispatch } = useContext(
    GroupConversationsContext
  );

  const { chatValue, chatDispatch } = useContext(ChatContext);

  let timer = null;
  useEffect(() => {
    loadConversations();
    timer = setInterval(loadConversations, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [authValue.user.id]);

  const loadConversations = () => {
    axios
      .get(`${BACKEND_URL}/groupconversations`)
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          groupConvDispatch({
            type: LOAD_GROUP_CONVERSATIONS,
            payload: sortConversation(data),
          });
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Server Error");
        console.log(err);
        clearInterval(timer);
      });
  };

  const handleCreateModalShow = () => {
    setShowCreateModal((prevState) => !prevState);
  };

  const handleAddParticipantModalShow = (conv) => {
    setShowAddParticipantModal((prevState) => !prevState);
    if (!showAddParticipantModal) {
      handleAvailableMembers(conv);
    }
  };

  const handleAllParticipantModalShow = (conv) => {
    setShowAllParticipantModal((prevState) => !prevState);
    if (!showAllParticipantModal) {
      setCurrentConv(conv);
    }
  };

  const handleAvailableMembers = (conv) => {
    let allcontacts = [];

    if (authValue.user.type === 3) {
      allcontacts = [
        ...authValue.user.profile.parents,
        ...authValue.user.profile.students,
      ];
    }

    if (authValue.user.type === 4) {
      allcontacts = [
        ...authValue.user.profile.teachers,
        ...authValue.user.profile.students,
      ];
    }

    if (authValue.user.type === 5) {
      allcontacts = [
        ...authValue.user.profile.teachers,
        authValue.user.profile.parent,
      ];
    }

    let availableToParticipate = [];

    if (conv) {
      availableToParticipate = allcontacts.filter(
        (cont) => !conv.participants.find((p) => p.user_id === cont.user_id)
      );
    }

    setAvailableMembers(availableToParticipate);
    setCurrentConv(conv);
  };

  const createConversation = (name, callback) => {
    axios
      .post(`${BACKEND_URL}/createGroupConversation`, { name })
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          groupConvDispatch({
            type: LOAD_GROUP_CONVERSATIONS,
            payload: sortConversation(data),
          });
          handleCreateModalShow();
          toast.success(message);
          callback();
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Group Creation Faild!");
      });
  };

  const addParitcipant = (userId, callback) => {
    axios
      .post(`${BACKEND_URL}/addmember`, {
        group_conversation_id: currentConv.id,
        user_id: userId,
      })
      .then((res) => {
        const { data, message, status } = res.data;
        if (status) {
          handleAvailableMembers(
            data.find((conv) => conv.id === currentConv.id) || currentConv
          );
          groupConvDispatch({
            type: LOAD_GROUP_CONVERSATIONS,
            payload: sortConversation(data),
          });

          toast.success(message);
        } else {
          toast.error(message);
        }
        callback();
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
      });
  };

  const removeParitcipant = (conv, userId, callback = () => {}) => {
    axios
      .post(`${BACKEND_URL}/removemember`, {
        gorup_conversation_id: conv.id,
        user_id: userId,
      })
      .then((res) => {
        const { data, message, status } = res.data;
        if (status) {
          handleAvailableMembers(
            data.find((conv) => conv.id === currentConv.id) || currentConv
          );
          groupConvDispatch({
            type: LOAD_GROUP_CONVERSATIONS,
            payload: sortConversation(data),
          });

          toast.success(message);
          callback();
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        console.log(err);
      });
  };

  const deleteConversation = (conv) => {
    if (window.confirm("Are You Sure??")) {
      axios
        .post(`${BACKEND_URL}/deletegroupconversations`, {
          gorup_conversation_id: conv.id,
        })
        .then((res) => {
          const { data, message, status } = res.data;
          if (status) {
            groupConvDispatch({
              type: LOAD_GROUP_CONVERSATIONS,
              payload: sortConversation(data),
            });

            toast.success(message);
          } else {
            toast.error(message);
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
          console.log(err);
        });
    }
  };

  const startChatting = (conv) => {
    if (chatValue.show) {
      chatDispatch({
        type: HIDE_CHAT_BOX,
        payload: { conversationId: null, partner: null },
      });
    }
    groupChatDispatch({
      type: SHOW_GROUP_CHAT_BOX,
      payload: conv,
    });
  };

  return (
    <div className="col-sm-6 col-md-4 mt-4">
      <div className="box px-3 pb-3">
        <div className="clearfix my-3">
          <h4 className="float-left">Group Coversations</h4>
          <button
            className="float-right btn btn-success btn-sm"
            onClick={handleCreateModalShow}
          >
            <i className="fas fa-plus"></i>
          </button>
        </div>
        <hr />

        <div style={{ maxHeight: 440, overflowY: "auto" }}>
          {groupConvValue.map((conv, i) => (
            <SingleConversation
              conv={conv}
              key={i}
              handleAddParticipantModalShow={handleAddParticipantModalShow}
              handleAllParticipantModalShow={handleAllParticipantModalShow}
              removeParitcipant={removeParitcipant}
              deleteConversation={deleteConversation}
              startChatting={startChatting}
            />
          ))}
        </div>
      </div>
      <CreateConversationModal
        show={showCreateModal}
        handleShow={handleCreateModalShow}
        createConversation={createConversation}
      />
      <AddParticipantModal
        show={showAddParticipantModal}
        handleShow={handleAddParticipantModalShow}
        addParitcipant={addParitcipant}
        availableMembers={availableMembers}
      />

      <AllParticipantModal
        show={showAllParticipantModal}
        handleShow={handleAllParticipantModalShow}
        removeParitcipant={removeParitcipant}
        conv={currentConv}
      />
    </div>
  );
}

function SingleConversation({
  conv,
  handleAddParticipantModalShow,
  handleAllParticipantModalShow,
  removeParitcipant,
  deleteConversation,
  startChatting,
}) {
  const { authValue } = useContext(AuthContext);

  const leaveHandler = () => {
    if (window.confirm("Are You Sure??")) {
      removeParitcipant(conv, authValue.user.id);
    }
  };
  return (
    <div className="my-2 d-flex justify-content-between align-items-center py-2 px-2 bg-light rounded">
      <div className="d-flex align-items-center">
        <img width={40} src={userImage} alt="" />
        <div className="pl-3">
          <strong>{conv.name}</strong>
          <br />
          <small className="text-muted">{getDiff(conv.updatedTime)} ago</small>
        </div>
      </div>
      <div>
        <a href="#!" className=" btn text-dark" data-toggle="dropdown">
          <span className="ml-3 mr-2">
            <i className="fas fa-ellipsis-v"></i>
          </span>
        </a>
        <div className="dropdown-menu rounded dropdown-menu-sm dropdown-menu-right border-0">
          <span className="dropdown-item text-muted"></span>

          <button className="dropdown-item" onClick={() => startChatting(conv)}>
            <i class="fas fa-comment"></i>
            <span className="ml-2">Start Chatting</span>
          </button>

          <button
            className="dropdown-item"
            onClick={() => handleAddParticipantModalShow(conv)}
          >
            <i className="fas fa-user-plus"></i>
            <span className="ml-2">Add Participant</span>
          </button>

          <button
            onClick={() => handleAllParticipantModalShow(conv)}
            className="dropdown-item"
          >
            <i className="fas fa-users"></i>
            <span className="ml-2"> All Participant</span>
          </button>

          <button
            onClick={() => leaveHandler(conv)}
            className="dropdown-item text-danger"
          >
            <i className="fas fa-sign-out-alt"></i>
            <span className="ml-2">Leave</span>
          </button>

          {authValue.user.id === conv.owner.id && (
            <button
              onClick={() => deleteConversation(conv)}
              className="dropdown-item text-danger"
            >
              <i className="fas fa-trash"></i>
              <span className="ml-2">Delete</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

function CreateConversationModal({ show, handleShow, createConversation }) {
  const [state, setState] = useState({
    name: "",
    isLoading: false,
  });

  const onchangeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, isLoading: true }));
    createConversation(state.name, () =>
      setState({ name: "", isLoading: false })
    );
  };

  return (
    <Modal
      show={show}
      onExit={() =>
        setState({
          name: "",
          isLoading: false,
        })
      }
    >
      <Modal.Header>
        <Modal.Title>Create Group</Modal.Title>

        <Button variant="danger" onClick={handleShow}>
          <i className="fas fa-times"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group className="mb-3">
            <Form.Label>Gorup Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Group Name"
              name="name"
              value={state.name}
              onChange={onchangeHandler}
              disabled={state.isLoading}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="w-100"
            type="submit"
            disabled={state.isLoading}
          >
            {state.isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function AddParticipantModal({
  show,
  handleShow,
  addParitcipant,
  availableMembers,
}) {
  const { authValue } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const addParitcipantHandler = (id) => {
    setIsLoading(true);

    addParitcipant(id, () => setIsLoading(false));
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Add Paritcipant</Modal.Title>

        <Button variant="danger" onClick={handleShow}>
          <i className="fas fa-times"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {availableMembers.map((mbr, indx) => (
          <div
            className="border shadow-sm mt-2 rounded px-2 py-2 d-flex justify-content-between align-itmes-center"
            key={indx}
          >
            <div>
              {" "}
              <strong>{mbr.name}</strong>
              <br />
              <span style={{ fontSize: 12 }}>ID: {mbr.id}</span>
            </div>
            <button
              onClick={() => addParitcipantHandler(mbr.user_id)}
              className="btn btn-sm btn-primary"
              disabled={isLoading}
            >
              {isLoading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                <i className="fas fa-plus"></i>
              )}
            </button>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}

function AllParticipantModal({ conv, show, handleShow, removeParitcipant }) {
  const { authValue } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const removeParitcipantHandler = (usrId) => {
    setIsLoading(true);
    removeParitcipant(conv, usrId, () => setIsLoading(false));
  };
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>All Paritcipant</Modal.Title>

        <Button variant="danger" onClick={handleShow}>
          <i className="fas fa-times"></i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {conv &&
          conv.participants.map((mbr, indx) => (
            <div
              className="border shadow-sm mt-2 rounded px-2 py-2 d-flex justify-content-between align-itmes-center"
              key={indx}
            >
              <div>
                {" "}
                <strong>{mbr.name}</strong>
                <br />
                <span style={{ fontSize: 12 }}>ID: {mbr.id}</span>
              </div>

              {authValue.user.id === conv.owner.id && (
                <>
                  {mbr.user_id !== authValue.user.id && (
                    <button
                      onClick={() => removeParitcipantHandler(mbr.user_id)}
                      className="btn btn-sm btn-danger"
                      title="Remove From Conversation"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fas fa-times"></i>
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          ))}
      </Modal.Body>
    </Modal>
  );
}

export default GroupConversations;
