
export const loadSchedule = (state, schedules) => {
    return {...state, isLoaded: true, schedules}
}

export const updateSchedule = (state, schedule) => {
    // console.log(schedule);
    const index = state.schedules.findIndex( s => s.id === schedule.id);
    const before = state.schedules.slice(0, index);
    const after = state.schedules.slice(index + 1);

    const updateSchedules = [...before, schedule, ...after];
    return {...state, schedules: updateSchedules}
}