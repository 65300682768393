import testIcon from "../images/test.png";

function TestsWidget() {
  return (
    <div className="col-12 col-sm-4 mt-3">
      <div className="box py-3 px-2">
        <div>
          <img src={testIcon} alt="" />
          <strong className="ml-2">Tests</strong>
        </div>
        <div className="mt-1 bg-light rounded px-3 pb-2 pt-1">
          <small>
            <strong>Test On Chapter One</strong>
          </small>
          <br />
          <small>
            <i className="fas fa-calendar mr-2"></i> 22-02-2022
          </small>
        </div>
        <div className="mt-1 bg-light rounded px-3 pb-2 pt-1">
          <small>
            <strong>Test On Chapter One</strong>
          </small>
          <br />
          <small>
            <i className="fas fa-calendar mr-2"></i> 22-02-2022
          </small>
        </div>
        <div className="text-center">
        <button className="eq-main-btn mt-2 px-4"> <small>View All</small></button>
        </div>
      
      </div>
    </div>
  );
}

export default TestsWidget;
