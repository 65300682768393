import userImage from "../images/user.png";
import { BACKEND_URL } from "../utils/serverInfo";
import { getDiff, sortConversation } from "../utils/utils";
import { AuthContext, ChatContext, ConversationsContext, GroupChatContext } from "../contexts";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { HIDE_GROUP_CHAT_BOX, LOAD_CONVERSATIONS, SHOW_CHAT_BOX } from "../actions/types";

function Conversations() {
  const { convValue, convDispatch } = useContext(ConversationsContext);
  const { authValue } = useContext(AuthContext);

  let timer = null;
  useEffect(() => {
    loadConversations();
    timer = setInterval(loadConversations, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [authValue.user.id]);

  const loadConversations = () => {
    axios
      .get(`${BACKEND_URL}/conversations`)
      .then((res) => {
        const { status, data, message } = res.data;
        if (status) {
          convDispatch({
            type: LOAD_CONVERSATIONS,
            payload: sortConversation(data),
          });
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Server Error");
        console.log(err);
      });
  };
  return (

        <div style={{ maxHeight: 440, overflowY: "auto" }}>
          {convValue.map((conv, i) => (
            <SingleConversation conv={conv} key={i} />
          ))}
        </div>

  );
}

const SingleConversation = ({ conv }) => {
  const {chatDispatch } = useContext(ChatContext);
  const { groupChatDispatch } = useContext(GroupChatContext);
  const { authValue } = useContext(AuthContext);
  const [partner, setPartner] = useState({});

  const viewChatbox = (convId, partnar) => {

    groupChatDispatch({
      type: HIDE_GROUP_CHAT_BOX
    });

    chatDispatch({
      type: SHOW_CHAT_BOX,
      payload: {
        conversationId: convId,
        partner: partnar,
      },
    });
  };
  useEffect(() => {
    let p = conv.partnerOne;

    if (authValue.user.id === p.id) {
      p = conv.partnerTwo;
    }
    p.diff = getDiff(conv.updatedTime);
    setPartner(p);
  }, [conv]);
  return (
    <div className="my-2 d-flex justify-content-between align-items-center py-2 px-2 bg-light rounded">
      <div className="d-flex align-items-center">
        <img width={40} src={userImage} alt="" />
        <div className="pl-3">
          <strong>{(() => (partner ? partner.name : ""))()}</strong>
          <br />
          <small className="text-muted">
            {(() => (partner ? partner.diff : ""))()} ago
          </small>
        </div>
      </div>
      <button
        onClick={() => viewChatbox(conv.id, partner)}
        className="eq-main-btn px-3"
      >
        View
      </button>
    </div>
  );
};

export default Conversations;
