export const START_LOADING = "START LOADING"
export const STOP_LOADING = "STOP LOADING"
export const LOG_IN = 'LOGIN';
export const SET_USER = 'SET USER';
export const LOG_OUT = 'LOG OUT';

export const ADD_STUDENT = 'ADD_STUDENT';
export const LOAD_STUDENT = 'LOAD_STUDENT';

export const LOAD_SCHEDULES = 'LOAD_SCHEDULES';
export const UPDATE_SHCEDULE = 'UPDATE_SCHEDULE';

export const SHOW_CHAT_BOX = 'SHOW_CHAT_BOX';
export const HIDE_CHAT_BOX = 'HIDE_CHAT_BOX';

export const LOAD_CONVERSATIONS = 'LOAD_CONVERSATIONS';
export const CLEAN_CONVERSATIONS = 'CLEAN_CONVERSATIONS';

export const SHOW_GROUP_CHAT_BOX = 'SHOW_GROUP_CHAT_BOX';
export const HIDE_GROUP_CHAT_BOX = 'HIDE_GROUP_CHAT_BOX';

export const LOAD_GROUP_CONVERSATIONS = 'LOAD_GROUP_CONVERSATIONS';
export const CLEAN_GROUP_CONVERSATIONS = 'CLEAN_GROUP_CONVERSATIONS'
