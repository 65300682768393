import axios from "axios";
import { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { LOG_IN } from "../../actions/types";
import { AuthContext } from "../../contexts";

import { BACKEND_URL } from "../../utils/serverInfo";
import { setTokenToHeader } from "../../utils/utils";

function Register() {
  const [registerData, setRegisterData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const { authValue, authDispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const phoneReff = useRef();

  const registerHandler = (e) => {
    e.preventDefault();
    setDisabled(true);
    axios
      .post(`${BACKEND_URL}/auth/register`, registerData)
      .then((res) => {
        let { status, data, message } = res.data;
        if (status) {
          toast.success(message);

          setDisabled(false);
          authDispatch({
            type: LOG_IN,
            payload: data,
          });
        } else {
          setDisabled(false);
          toast.error(message);
        }
      })
      .catch((e) => {
        setDisabled(false);
      });
  };

  useEffect(() => {
    let token = setTokenToHeader();
    if (token) {
      navigate("/");
    }
  }, [authValue.isAuthenticated, navigate]);

  document.title = "EQ :: Register";

  const changeHandler = (e) => {
    setRegisterData((prevSatate) => {
      return {
        ...prevSatate,
        [e.target.name]: e.target.value,
      };
    });
  };

  const phoneHandler = (r) => {
    let intTel = phoneReff.current;
    let { dialCode } = intTel.selectedCountryData;

    let phone = "+" + dialCode + Number(intTel.state.value);
    setRegisterData((prevSatate) => {
      return {
        ...prevSatate,
        phone,
      };
    });
  };

  const flagHandler = () => {
    let intTel = phoneReff.current;
    let { name, dialCode } = intTel.selectedCountryData;
    let country = name.split("(")[0];

    intTel.state.value = "";
    if (dialCode == 243) {
      country = "Congo (DRC)";
    }

    if (dialCode == 242) {
      country = "Congo (Republic)";
    }

    setRegisterData((prevSatate) => {
      return {
        ...prevSatate,
        country,
      };
    });
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card box px-2">
            <div className="card-header">LOG IN</div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label htmlFor="name">Your Name</label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Enter Name"
                        name="name"
                        value={registerData.name}
                        onChange={changeHandler}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Enter Email"
                        name="email"
                        value={registerData.email}
                        onChange={changeHandler}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label htmlFor="phone">Phone Number</label>
                      <IntlTelInput
                        fieldName="phone"
                        fieldId="phone"
                        inputClassName="form-control"
                        containerClassName="intl-tel-input w-100"
                        onPhoneNumberChange={phoneHandler}
                        onSelectFlag={flagHandler}
                        ref={phoneReff}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label htmlFor="country">Your Country</label>
                      <input
                        type="text"
                        id="country"
                        className="form-control"
                        placeholder="Enter Country"
                        name="country"
                        value={registerData.country}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        value={registerData.password}
                        onChange={changeHandler}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label htmlFor="password_confirmation">
                        {" "}
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="password_confirmation"
                        className="form-control"
                        name="password_confirmation"
                        placeholder="Confirm Password"
                        value={registerData.password_confirmation}
                        onChange={changeHandler}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-sm-6">
                    <button
                      onClick={(e) => registerHandler(e)}
                      type="submit"
                      className="btn btn-success btn-block mt-1"
                      disabled={disabled}
                    >
                      {disabled ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
                <div className="mt-3">
                  <span>Already Registered ?</span>{" "}
                  <Link to="/login">Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
