function FullPageLoader() {
  return (
    <div
      style={{ height: "100vh", minHeight: 300 }}
      className="d-flex justify-content-center align-items-center"
    >
      <i className="fas fa-spinner fa-spin fa-4x"></i>
    </div>
  );
}

export default FullPageLoader;
