import Conversations from "../Components/Conversations";
import GroupConversations from "../Components/GroupConversations";
import withMainPage from "../HOC/withMainPage";

function Chats() {
  document.title = "EQ :: Chats";

  return (
    <>
      <div className="row mt-5 mb-4">
        <div className="col-sm-6 col-md-4 mt-4">
          <div className="box px-3 pb-3">
            <h4 className="my-4">Coversations</h4>
            <hr />
            <Conversations />
          </div>
        </div>
        <GroupConversations />
      </div>
    </>
  );
}

export default withMainPage(Chats);
