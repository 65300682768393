import chatsImage from "../images/chats.png";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../utils/serverInfo";
import { toast } from "react-toastify";
import { HIDE_GROUP_CHAT_BOX } from "../actions/types";
import { AuthContext, GroupChatContext } from "../contexts";
import { getDiff } from "../utils/utils";

function GroupChatBox() {
  const { groupChatValue, groupChatDispatch } = useContext(GroupChatContext);
  const { authValue } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  let [msg, setMsg] = useState("");
  let [msgSending, setMsgSending] = useState(false);
  let [firstLoaded, setFirstLoaded] = useState(false);
  let [smallId, setSmallId] = useState(null);
  let [loadingPrevious, setLoadingPrevious] = useState(false);
  let firstLoadRef = useRef();
  firstLoadRef.current = firstLoaded;

  const msgBoxRef = useRef();
  const msgTopRef = useRef();
  const msgBottomRef = useRef();

  let timer = null;

  useEffect(() => {
    clearInterval(timer);
    setFirstLoaded(false);
    setSmallId(null);
    setMessages(() => []);

    if (groupChatValue.conversation) {
      loadMassage();
      timer = setInterval(loadMassage, 5000);
    }

    return () => {
      clearInterval(timer);
      setFirstLoaded(false);
      setSmallId(null);
      setMessages(() => []);
    };
  }, [groupChatValue.conversation.id]);

  const onChangeHander = (e) => {
    setMsg(e.target.value);
  };

  const afterLoadMesage = (msgs, putAfter = true) => {
    setMessages((messages) => {
      let newMsgArry = [];
      if (putAfter) {
        newMsgArry = [...messages, ...msgs];
      } else {
        newMsgArry = [...msgs, ...messages];
      }

      newMsgArry = newMsgArry
        .sort((msgA, msgB) => msgA.id - msgB.id)
        .filter((item, pos, ary) => {
          return !pos || item.id !== ary[pos - 1].id;
        });

      return newMsgArry;
    });
  };

  const loadMassage = () => {
    axios
      .get(`${BACKEND_URL}/getgroupmessage/${groupChatValue.conversation.id}`)
      .then((res) => {
        const { status, message, data } = res.data;
        if (status) {
          afterLoadMesage(data.messages);
          scrollToEnd();
          if (data.messages.length > 0) {
            setSmallId(data.messages[data.messages.length - 1].id);
          } else {
            setSmallId(null);
          }
        } else {
          toast.error(message);
          clearInterval(timer);
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong!!");
        console.log(err);
        clearInterval(timer);
      });
  };

  const loadPreviousMassage = () => {
    if (smallId) {
      if (!loadingPrevious) {
        setLoadingPrevious(true);
        axios
          .post(
            `${BACKEND_URL}/getPreviousGroup/${groupChatValue.conversation.id}`,
            { smallId }
          )
          .then((res) => {
            const { status, data } = res.data;
            if (status) {
              afterLoadMesage(data.messages, false);
              if (data.messages.length > 0) {
                setSmallId(data.messages[data.messages.length - 1].id);
              } else {
                setSmallId(null);
              }
            }
            setLoadingPrevious(false);
          })
          .catch((err) => {
            toast.error("Something Went Wrong!!");
            console.log(err);
            setLoadingPrevious(false);
          });
      }
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();

    if (!msgSending) {
      setMsgSending(true);

      axios
        .post(`${BACKEND_URL}/sendgroupmessage`, {
          group_conversation_id: groupChatValue.conversation.id,
          message: msg,
        })
        .then((res) => {
          const { status, data, message } = res.data;
          if (status) {
            afterLoadMesage([data]);
            setMsg("");
            scrollToEnd();
          } else {
            toast.error(message);
          }
          setMsgSending(false);
        })
        .catch((err) => {
          toast.error("Error From Server!");
          console.log(err);
          setMsgSending(false);
        });
    }
  };

  const closeChatBox = () => {
    clearInterval(timer);
    groupChatDispatch({
      type: HIDE_GROUP_CHAT_BOX,
    });
  };

  const scrollToEnd = () => {
    let parent = null;
    let msgBottom = null;
    let downValue = 0;

    if (msgBoxRef.current) {
      parent = msgBoxRef.current.getBoundingClientRect();
    }

    if (msgBottomRef.current) {
      msgBottom = msgBottomRef.current.getBoundingClientRect();
    }

    if (msgBottom && parent) {
      downValue = msgBottom.bottom - parent.bottom;
    }

    if (firstLoadRef.current) {
      if (downValue < 200 && downValue > -10) {
        if (msgBottomRef.current) {
          msgBottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      if (msgBottomRef.current) {
        msgBottomRef.current.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => setFirstLoaded(true), 200);
      }
    }
  };

  const scrollHandler = () => {
    let parent = msgBoxRef.current.getBoundingClientRect();
    let msgTop = msgTopRef.current.getBoundingClientRect();
    let topValue = parent.top - msgTop.top;

    if (topValue <= 10) {
      if (firstLoadRef.current) {
        loadPreviousMassage();
      }
    }
  };

  return (
    <div className="chat-box">
      <div className="box py-3 px-2">
        <div className="clearfix">
          <div className="float-left">
            <img src={chatsImage} alt="" />
            <strong className="ml-2">{groupChatValue.conversation.name}</strong>
          </div>
          <div className="float-right">
            <button
              onClick={closeChatBox}
              className="btn btn-light text-dark btn-sm"
            >
              X
            </button>
          </div>
        </div>

        <hr />

        <div
          style={{ height: 340, overflow: "auto" }}
          className="my-1 p-2 bg-light"
          ref={msgBoxRef}
          onScroll={scrollHandler}
        >
          <div ref={msgTopRef}></div>
          {messages.map((msg, i) => {
            msg.isWoner = false;
            if (msg.sender_id === authValue.user.id) {
              msg.isWoner = true;
            }
            return (
              <Message key={i} message={msg} partner={groupChatValue.partner} />
            );
          })}
          <div ref={msgBottomRef}></div>
        </div>
        <form onSubmit={sendMessage}>
          <div className="row">
            <div className="col-9">
              <input
                onChange={onChangeHander}
                type="text"
                className="form-control w-100"
                value={msg}
              />
            </div>
            <div className="col-3">
              <button className="btn btn-success btn-block">
                <div className="fa fa-paper-plane"></div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

function Message({ message }) {
  return (
    <div className="clearfix mb-2 text-white">
      <div className={`float-${message.isWoner ? "right" : "left"}`}>
        {message.isWoner || (
          <div style={{fontSize: 12}} className="bg-dark p-1 rounded d-inline-block">{message.sender_name}</div>
        )}
    
        <div
          className={`py-2 px-3 bg-${
            message.isWoner ? "dark" : "primary"
          } rounded`}
        >
          {message.message}
        </div>
      </div>
    </div>
  );
}

export default GroupChatBox;
