import { HIDE_GROUP_CHAT_BOX, SHOW_GROUP_CHAT_BOX } from "../actions/types";

export const groupChatReducer = (state, action) => {
  switch (action.type) {
    case SHOW_GROUP_CHAT_BOX:
      return { show: true , conversation: action.payload};
    case HIDE_GROUP_CHAT_BOX:
      return { show: false, conversation: null};
    default:
      return state;
  }
};
