import axios from "axios";
import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SET_USER } from "../../actions/types";
import { AuthContext } from "../../contexts";
import withMainPage from "../../HOC/withMainPage";
import { BACKEND_URL } from "../../utils/serverInfo";

function ChangeEmail() {
  const [confirmCode, setConfirmCode] = useState(false);

  return (
    <>
      {confirmCode ? (
        <ConfirmCodeForm />
      ) : (
        <NewEmailForm setConfirmCode={setConfirmCode} />
      )}
    </>
  );
}

function NewEmailForm({ setConfirmCode }) {
  const [isLoading, setLoading] = useState(false);
  const [submitData, setSubmitData] = useState({
    password: "",
    newEmail: "",
  });

  const passRef = useRef();
  const newEmailRef = useRef();

  document.title = "EQ :: Change Email";

  const onChangeHandler = (e) => {
    setSubmitData({ ...submitData, [e.target.id]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!submitData.password) {
      toast.error("Password is Required!");
      passRef.current.focus();
      return;
    }

    if (!submitData.newEmail) {
      toast.error("Please Enter New Email!");
      newEmailRef.current.focus();
      return;
    }

    setLoading(true);
    axios
      .post(`${BACKEND_URL}/auth/change_email`, submitData)
      .then((res) => {
        const { status, message } = res.data;
        if (status) {
          toast.success(message);
          setConfirmCode(true);
        } else {
          toast.error(message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
      });
  };

  return (
    <div className="row mt-4 justify-content-center">
      <div className="col-md-4 col-sm-6">
        <div className="box py-4 px-3">
          <form onSubmit={submitHandler}>
            <h3>Change Email</h3>

            <input
              type="email"
              className="form-control my-3"
              id="newEmail"
              placeholder="New Email"
              value={submitData.newEmail}
              onChange={onChangeHandler}
              ref={newEmailRef}
              disabled={isLoading}
              autoComplete="off"
            />

            <input
              type="password"
              className="form-control my-3"
              id="password"
              placeholder="Password"
              value={submitData.password}
              onChange={onChangeHandler}
              ref={passRef}
              disabled={isLoading}
              autoComplete="off"
            />

            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={isLoading}
            >
              {isLoading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

function ConfirmCodeForm({ setConfirmCode }) {
  const [isLoading, setLoading] = useState(false);
  const {authDispatch} = useContext(AuthContext)
  const [code, setCode] = useState();
  const codeRef = useRef();
  const navigate = useNavigate();

  document.title = "EQ :: Change Password";

  const onChangeHandler = (e) => {
    setCode(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!code) {
      toast.error("Code is Required!");
      codeRef.current.focus();
      return;
    }
    setLoading(true);
    axios
      .post(`${BACKEND_URL}/auth/confirm_email_code`, { code })
      .then((res) => {
        const { status, message, data } = res.data;
        if (status) {
          toast.success(message);
          setLoading(false);
          authDispatch({
            type: SET_USER,
            payload: data
          })
          navigate("/profile");
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Somethig Went Wrong!");
        setLoading(false);
      });
  };

  return (
    <div className="row mt-4 justify-content-center">
      <div className="col-md-4 col-sm-6">
        <div className="box py-4 px-3">
          <form onSubmit={submitHandler}>
            <h3>Confirm Code</h3>
            <input
              type="code"
              className="form-control my-3"
              id="code"
              placeholder="Confirmation Code"
              value={code}
              onChange={onChangeHandler}
              ref={codeRef}
              disabled={isLoading}
            />
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={isLoading}
            >
              {isLoading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withMainPage(ChangeEmail);
